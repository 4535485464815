/* eslint-disable camelcase, @typescript-eslint/camelcase */

import { fetchAPI } from "./api";
import { IAuth } from "./types/auth";


export function setAccessToken(accessToken: string): void {
  return localStorage.setItem("access_token", accessToken);
}

export function getAccessToken(): string {
  return localStorage.getItem("access_token") || "";
}

export function setRefreshToken(refreshToken: string): void {
  return localStorage.setItem("refresh_token", refreshToken);
}

export function getRefreshToken(): string {
  return localStorage.getItem("refresh_token") || "";
}

export function setExpiration(date: Date): void {
  return localStorage.setItem("expiration", `${date.getTime()}`);
}

export function getExpiration(): Date {
  return new Date(parseInt(localStorage.getItem("expiration") || ""));
}

export function refreshAccessToken(): Promise<string> {
  const refreshToken = getRefreshToken();
  return fetchAPI(`/auth/refresh?refresh_token=${refreshToken}`).then(
    ({ access_token, refresh_token, expires_in }: IAuth) => {
      setAccessToken(access_token);
      setRefreshToken(refresh_token);
      setExpiration(new Date(Date.now() + expires_in * 1000));
      return access_token;
    },
  );
}

export function isAuthenticated(): boolean {
  return getAccessToken() !== null && getRefreshToken() !== null;
}

export function login({ email, password }: { email: string; password: string }): Promise<string> {
  return fetchAPI(`/auth/token?email=${email}&password=${password}`).then(
    ({ refresh_token, access_token, expires_in }: IAuth) => {
      setRefreshToken(refresh_token);
      setAccessToken(access_token);
      setExpiration(new Date(Date.now() + expires_in * 1000));
      return access_token;
    },
  );
}
