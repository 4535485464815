import { CSSProperties } from "react";


export const styles: Record<string, CSSProperties> = {
  root: {
    padding: "20px",
    backgroundColor: "#252d47",
    margin: "10px 0px",
    borderRadius: "2px",
    boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.2)",
    display: "inline-block",
  },
};
