import fetch from "isomorphic-fetch";

import { isAuthenticated, getExpiration, getAccessToken, refreshAccessToken } from "./auth";


const API = "https://api.subreader.dk";

export function fetchAPI(endpoint: string, opts?: any): any {
  return fetch(`${API}${endpoint}`, {
    mode: "cors",
    ...opts,
  })
    .then(res => res.json())
    .then(json => {
      if (json.error) {
        throw json.error;
      }
      return json.data;
    });
}

export function request(endpoint: string, opts: any): any {
  if (!isAuthenticated()) {
    throw new Error("Not authenticated.");
  }
  return (getExpiration().getTime() > Date.now() ? Promise.resolve(getAccessToken()) : refreshAccessToken()).then(
    (accessToken: string) => {
      return fetchAPI(endpoint, {
        ...opts,
        headers: {
          ...opts.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
  );
}

export function query(graphqlQuery: string): any {
  const accessToken = getAccessToken();
  return request(`/analytics?token=${accessToken}`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      query: graphqlQuery,
    }),
  });
}
