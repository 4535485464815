import React, { FC, HTMLAttributes } from "react";
import { styles } from "./styles";


export const Card: FC<HTMLAttributes<HTMLDivElement>> = props => {
  const { style, children, ...rest } = props;
  return (
    <div
      {...rest}
      style={{
        ...styles.root,
        ...style,
      }}
    >
      {children}
    </div>
  );
};
